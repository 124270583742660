import { Dimensions } from 'react-native';

export const { height: WINDOW_HEIGHT, width: WINDOW_WIDTH } = Dimensions.get('window');

export const SMALL_DEVICE_WIDTH = 375;

export const isSmallDevice = WINDOW_WIDTH < SMALL_DEVICE_WIDTH;
export const isLongScreenDevice = WINDOW_HEIGHT / WINDOW_WIDTH >= 2;
export const isTablet = WINDOW_WIDTH >= 600;

export const DRAWER_SWIPE_EDGE_WIDTH = isTablet ? WINDOW_WIDTH / 5 : WINDOW_WIDTH / 2;
export const DRAWER_SWIPE_MIN_DISTANCE = 60;
export const LEFT_DRAWER_DEFAULT_ICON_WIDTH = 20;
export const LEFT_DRAWER_FOCUSED_ICON_WIDTH = 32;

export const DEFAULT_BACKGROUND_BLUR_RADIUS = 20;
