import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';

import { CustomIcon, CustomIconProps } from './CustomIcon';
import { useAppSelector } from '../../app/hooks';
import CustomText from '../../components/CustomText';
import { COLORS } from '../../constants';
import {
  selectDivRadius,
  selectMainColor,
} from '../../features/appconfig/store/selectors';
import { IS_WEB, isTablet } from '../constants';

interface Props {
  icon: CustomIconProps['name'];
  iconSize?: CustomIconProps['size'];
  iconColor?: CustomIconProps['color'];
  onPress?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  hidden?: boolean;
  disabled?: boolean;
  text?: string;
}

export const FloatingActionButton = (props: Props) => {
  const mainColor = useAppSelector(selectMainColor);
  const divRadius = useAppSelector(selectDivRadius);

  if (props.hidden) {
    return null;
  }

  const isTextButton = props.text && isTablet;

  return (
    <View style={[styles.container, props.containerStyle]}>
      <TouchableOpacity
        onPress={props.onPress}
        hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
        disabled={props.disabled}
      >
        <LinearGradient
          // gradient initilalized with a single color.
          // prepared for future addition of a secondary color.
          colors={[mainColor, mainColor]}
          start={{ x: 0, y: 0.5 }}
          end={{ x: 1, y: 0.5 }}
          style={[
            styles.button,
            {
              width: isTextButton ? 'auto' : 52,
              height: isTextButton ? mvs(52) : 52,
              borderRadius: isTextButton ? mvs(divRadius) : 26,
            },
            props.buttonStyle,
          ]}
        >
          <View
            style={[
              styles.buttonContainer,
              {
                paddingRight: isTextButton ? mvs(30) : 0,
                // offset to compensate for default empty space around icon
                paddingLeft: isTextButton ? mvs(25) : 0,
              },
            ]}
          >
            <CustomIcon
              name={props.icon}
              size={props.iconSize ?? 24}
              color={props.iconColor ?? '#fff'}
            />
            {isTextButton && (
              <CustomText style={styles.text} weight="SemiBold">
                {props.text}
              </CustomText>
            )}
          </View>
        </LinearGradient>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: isTablet ? mvs(24) : 24,
    bottom: isTablet ? mvs(24) : 24,
    shadowColor: '#000',
    shadowOffset: IS_WEB
      ? undefined
      : {
          width: -4,
          height: 4,
        },
    shadowOpacity: 0.4,
    shadowRadius: IS_WEB ? 0 : 30,
    elevation: 30,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: COLORS.WHITE,
    fontSize: mvs(18),
    marginLeft: mvs(5),
    marginBottom: 4,
  },
});
