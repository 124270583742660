import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { ShadowedView } from 'react-native-fast-shadow';
import { mvs } from 'react-native-size-matters';

import { CustomIcon, CustomIconProps } from './CustomIcon';
import { IS_ANDROID, IS_WEB, isTablet } from '../constants';

interface Props {
  icon?: CustomIconProps['name'];
  iconSize?: CustomIconProps['size'];
  iconColor?: CustomIconProps['color'];
  onPress?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  hidden?: boolean;
  disabled?: boolean;
}

export const FloatingExitButton = (props: Props) => {
  if (props.hidden) {
    return null;
  }

  return (
    <ShadowedView style={[styles.container, props.containerStyle]}>
      <TouchableOpacity
        onPress={props.onPress}
        hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
        disabled={props.disabled}
      >
        <View style={[styles.button, props.buttonStyle]}>
          <CustomIcon
            name={props.icon ?? 'icon_close'}
            size={props.iconSize ?? 24}
            color={props.iconColor ?? '#474747'}
          />
        </View>
      </TouchableOpacity>
    </ShadowedView>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: isTablet ? mvs(22) : 24,
    top: 24,
    shadowColor: '#000',
    shadowOffset: IS_WEB
      ? undefined
      : {
          width: -4,
          height: 4,
        },
    shadowOpacity: IS_ANDROID ? 0.2 : 0.4,
    shadowRadius: IS_WEB ? 0 : 30,
    elevation: 30,
  },
  button: {
    backgroundColor: '#fff',
    width: 46,
    height: 46,
    borderRadius: 23,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
