import { FONTS } from '../../constants';
import { FontFamily } from '../../models/Tab.model';

const weightMapping: Record<'Regular' | 'Bold' | 'SemiBold', FontWeight> = {
  Regular: '400Regular',
  Bold: '700Bold',
  SemiBold: '600SemiBold',
};

export const getFontFamily = (
  fontFamily: FontFamily,
  weight: 'Regular' | 'Bold' | 'SemiBold',
): string => {
  const weightKey = weightMapping[weight];
  const fontKey = `${fontFamily}_${weightKey}`;
  return FONTS[fontKey];
};

type FontWeight = '400Regular' | '700Bold' | '600SemiBold';


export const generateFontVariantsForRenderingRawHTML = (fontName: string): string[] => {
  const formattedName =
    fontName.charAt(0).toUpperCase() + fontName.slice(1).toLowerCase();

  return [`${formattedName}-Regular`, `${formattedName}-Bold`];
};
