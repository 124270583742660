import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerScreenProps,
} from '@react-navigation/drawer';
import { DrawerNavigationOptions } from '@react-navigation/drawer/lib/typescript/src/types';
import { indexOf } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import { Image, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { mvs } from 'react-native-size-matters';

import { useAppSelector } from '../app/hooks';
import CustomText from '../components/CustomText';
import { COLORS } from '../constants';
import {
  selectAppConfig,
  selectFontFamily,
  selectMenuBackgroundColor,
  selectMenuBackgroundImage,
  selectMenuColor,
  selectMenuLogo,
  selectTabs,
} from '../features/appconfig/store/selectors';
import i18n from '../localization/i18n';
import { AppConfigTabs, TabType } from '../models';
import AboutModalScreen from '../screens/AboutModalScreen';
import AlarmScreen from '../screens/AlarmScreen';
import AudioDedicationScreen from '../screens/AudioDedicationScreen';
import HtmlScreen from '../screens/HtmlScreen';
import LiveFeedScreen from '../screens/LiveFeedScreen';
import { PlayerScreen } from '../screens/PlayerScreen';
import RssScreen from '../screens/RssScreen';
import TextDedicationScreen from '../screens/TextDedicationScreen';
import WebsiteScreen from '../screens/WebsiteScreen';
import { getUrlFromImageName } from '../sdk/api/images';
import { ImageBackground } from '../sdk/components/ImageBackground';
import {
  DRAWER_SWIPE_EDGE_WIDTH,
  DRAWER_SWIPE_MIN_DISTANCE,
  isTablet,
  LEFT_DRAWER_DEFAULT_ICON_WIDTH,
  LEFT_DRAWER_FOCUSED_ICON_WIDTH,
} from '../sdk/constants';
import { isLastIndex } from '../sdk/helpers/array';
import { getFontFamily } from '../sdk/helpers/fontMapper';
import useDrawerWidth from '../sdk/hooks/useDrawerWidth';

interface MenuItem {
  name: string;
  component: ComponentType<any> | null; // FIXME Better typing, using LeftDrawerNavigatorScreenProps
  label: string;
  icon: string;
  options: AppConfigTabs[number];
  faIcon: string;
}

export type LeftDrawerNavigatorParams = {
  // FIXME Une solution temporaire a été faite pour utiliser des noms de routes dynamique plutôt que ce type pour éviter la contrainte d'unicité
  Player: undefined;
  Alarm: undefined;
  TextDedication: undefined;
  LiveFeed: undefined;
  Html: undefined;
  Website: undefined;
  Rss: undefined;
  AudioDedication: undefined;
};

export type LeftDrawerNavigatorScreenProps<
  Screen extends keyof LeftDrawerNavigatorParams,
> = DrawerScreenProps<LeftDrawerNavigatorParams, Screen>;

const Drawer = createDrawerNavigator<LeftDrawerNavigatorParams>();

function CustomDrawerContent(props: DrawerContentComponentProps) {
  const [aboutModalVisible, setAboutModalVisible] = useState(false);

  // FIXME Voir pour ajouter des props plus proprement
  const aboutView = !props.aboutVisibility ? (
    <>
      <TouchableOpacity style={styles.footer} onPress={() => setAboutModalVisible(true)}>
        <Image
          source={require('../assets/images/radioking-logo-small.png')}
          style={styles.radiokingLogoSmall}
        />
        <CustomText style={styles.footerText} weight="Regular">
          {i18n.t('leftDrawer.about')}
        </CustomText>
      </TouchableOpacity>
      <AboutModalScreen
        onCancel={() => setAboutModalVisible(false)}
        visible={aboutModalVisible}
      />
    </>
  ) : (
    <View />
  );

  return (
    <SafeAreaView style={styles.safeAreaContainer} edges={['bottom']}>
      <StatusBar
        animated={true}
        barStyle={'default'}
        translucent={true}
        backgroundColor={COLORS.TRANSPARENT}
      />
      <ImageBackground
        source={{ uri: props.backgroundImage }}
        style={{ backgroundColor: props.backgroundImageColor }}
        resizeMode="cover"
      >
        <SafeAreaView style={styles.container} edges={['top']}>
          <Image
            source={{ uri: props.menuLogo }}
            resizeMode="cover"
            style={styles.menuLogo}
          />
          <DrawerContentScrollView
            {...props}
            contentContainerStyle={styles.drawerContentContainer}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
          >
            <DrawerItemList {...props} />
          </DrawerContentScrollView>
          {aboutView}
        </SafeAreaView>
      </ImageBackground>
    </SafeAreaView>
  );
}

function CustomDrawerIcon({
  focused,
  color,
  icon,
  accessibilityLabel,
  testID,
  faIcon,
}: Parameters<NonNullable<DrawerNavigationOptions['drawerIcon']>>[0] & {
  icon: string;
  accessibilityLabel: string;
  testID: string;
  faIcon: string;
}) {
  // FIXME Remove this check, only here to avoid an annoying warning on Android because icons are undefined
  if (!icon) {
    return null;
  }

  const style = { tintColor: color };
  if (faIcon) {
    return (
      <FontAwesomeIcon
        size={20}
        secondaryColor={color}
        color={color}
        icon={faIcon}
        style={
          !focused
            ? [styles.drawerIcon, style]
            : [styles.drawerIcon, styles.drawerIconFocused, style]
        }
      />
    );
  } else {
    return (
      <Image
        source={{ uri: icon }}
        resizeMode="contain"
        style={
          !focused
            ? [styles.drawerIcon, style]
            : [styles.drawerIcon, styles.drawerIconFocused, style]
        }
        accessibilityRole="button"
        accessibilityLabel={accessibilityLabel}
        testID={testID}
      />
    );
  }
}

// FIXME Better typing, using LeftDrawerNavigatorScreenProps
export function convertTabTypeToScreen(tabType: TabType): ComponentType<any> | null {
  switch (tabType) {
    case TabType.Dedication:
      return TextDedicationScreen;
    case TabType.AudioDedication:
      return AudioDedicationScreen;
    case TabType.Html:
      return HtmlScreen;
    case TabType.LiveFeed:
      return LiveFeedScreen;
    case TabType.Radio:
      return PlayerScreen;
    case TabType.Alarm:
      return AlarmScreen;
    case TabType.Rss:
      return RssScreen;
    case TabType.Website:
      return WebsiteScreen;
    default:
      return null;
  }
}

export const LeftDrawerNavigator = () => {
  const tabs = useAppSelector(selectTabs);
  const appConfig = useAppSelector(selectAppConfig);
  const menuColor = useAppSelector(selectMenuColor); // FIXME Rassembler peut être ces 4 valeurs dans une seule méthode menuConfig
  const menuBackgroundColor = useAppSelector(selectMenuBackgroundColor);
  const menuBackgroundImage = useAppSelector(selectMenuBackgroundImage);
  const menuLogo = useAppSelector(selectMenuLogo);
  const fontFamily = useAppSelector(selectFontFamily);

  const [menuItems, setMenuItems] = useState<MenuItem[]>(createMenuItem());

  function createMenuItem(): MenuItem[] {
    return tabs.map((tab, index) => ({
      name: 'tab' + index,
      component: convertTabTypeToScreen(tab.type),
      label: tab.titre,
      icon: getUrlFromImageName(appConfig, tab.icon),
      options: tab,
      faIcon: tab.faIcon,
    }));
  }

  useEffect(() => {
    setMenuItems(createMenuItem());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  return (
    <Drawer.Navigator
      initialRouteName="Player"
      backBehavior="history"
      screenOptions={{
        headerShown: false,
        drawerPosition: 'left',
        drawerType: 'front',
        swipeEdgeWidth: DRAWER_SWIPE_EDGE_WIDTH,
        swipeMinDistance: DRAWER_SWIPE_MIN_DISTANCE,
        drawerStyle: [styles.drawer, { width: useDrawerWidth() }],
        drawerItemStyle: styles.drawerItem,
        drawerLabelStyle: [
          styles.drawerLabel,
          { fontFamily: getFontFamily(fontFamily, 'Regular') },
        ],
        drawerActiveBackgroundColor: 'transparent',
        drawerInactiveBackgroundColor: 'transparent',
        drawerActiveTintColor: menuColor,
        drawerInactiveTintColor: menuColor,
      }}
      drawerContent={props => (
        <CustomDrawerContent
          {...props}
          backgroundImage={getUrlFromImageName(appConfig, menuBackgroundImage)}
          backgroundImageColor={menuBackgroundColor}
          menuLogo={getUrlFromImageName(appConfig, menuLogo)}
          aboutVisibility={appConfig.marqueBlancheOption === 'YES'}
        />
      )}
    >
      {menuItems.map((menuItem, index) => (
        <Drawer.Screen
          initialParams={{ options: menuItem.options }}
          key={menuItem.name}
          name={menuItem.name}
          options={{
            drawerItemStyle: {
              ...styles.drawerItem,
              ...(isLastIndex(menuItems, index) ? styles.drawerItemLastChild : {}),
            },
            drawerLabel: menuItem.label,
            drawerIcon: props => (
              <CustomDrawerIcon
                {...props}
                icon={menuItem.icon}
                color={menuColor}
                accessibilityLabel={'Tab' + indexOf(menuItems, menuItem)}
                testID={'Tab' + indexOf(menuItems, menuItem)}
                faIcon={menuItem.faIcon}
              />
            ),
          }}
          getComponent={() => menuItem.component}
        />
      ))}
    </Drawer.Navigator>
  );
};

const styles = StyleSheet.create({
  drawer: {
    backgroundColor: 'transparent',
  },
  drawerContentContainer: {
    paddingTop: mvs(10),
    marginHorizontal: mvs(24),
  },
  drawerItem: {
    marginHorizontal: 0,
    marginVertical: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#d9d9d9',
  },
  drawerItemLastChild: {
    borderBottomWidth: 0,
  },
  drawerIcon: {
    marginLeft: -8, // Padding of 8 is hardcoded in react-navigation
    minHeight: LEFT_DRAWER_DEFAULT_ICON_WIDTH,
    minWidth: LEFT_DRAWER_DEFAULT_ICON_WIDTH,
    alignItems: 'center',
  },
  drawerIconFocused: {
    minHeight: LEFT_DRAWER_FOCUSED_ICON_WIDTH,
    minWidth: LEFT_DRAWER_FOCUSED_ICON_WIDTH,
  },
  drawerLabel: {
    fontSize: mvs(18),
    marginLeft: -32 + mvs(15), // Left margin of 32 is hardcoded in react-navigation
  },
  container: {
    flex: 1,
    paddingTop: 20,
  },
  footer: {
    backgroundColor: '#F9F9F9',
    height: isTablet ? mvs(50) : mvs(60),
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  radiokingLogoSmall: {
    aspectRatio: 81 / 98,
    width: 23,
  },
  footerText: {
    color: '#9C9C9C',
    fontSize: isTablet ? mvs(15) : mvs(16),
    marginLeft: mvs(15.65),
  },
  menuLogo: {
    marginTop: mvs(16),
    marginHorizontal: mvs(24),
    aspectRatio: 552 / 248, // Ratio connu de l'image reçu
  },
  safeAreaContainer: {
    flex: 1,
    backgroundColor: '#F9F9F9',
  },
});
