import { DrawerActions, useIsFocused } from '@react-navigation/native';
import { Switch } from '@rneui/themed';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { mvs } from 'react-native-size-matters';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import CustomText from '../components/CustomText';
import { COLORS } from '../constants';
import { selectAlarms } from '../features/alarm/store/selectors';
import { addAlarm, AlarmData } from '../features/alarm/store/slice';
import { selectFontFamily, selectDivRadius, selectMainColor } from '../features/appconfig/store/selectors';
import { formatForAlarm } from '../localization/datetime';
import i18n from '../localization/i18n';
import { AlarmClockTabModel } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';
import { CustomIcon } from '../sdk/components/CustomIcon';
import { days as dayz } from '../sdk/components/DayMultiselect';
import { FloatingActionButton } from '../sdk/components/FloatingActionButton';
import { ScreenLayout } from '../sdk/components/ScreenLayout';
import { isTablet, WINDOW_WIDTH } from '../sdk/constants';
import { getFontFamily } from '../sdk/helpers/fontMapper';

export default function AlarmScreen({
  route,
  navigation,
}): LeftDrawerNavigatorScreenProps<'Rss'> {
  const tabOptions = route.params.options as AlarmClockTabModel;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [enableRefreshTab, setEnableRefreshTab] = useState([]);
  const alarms = useAppSelector(selectAlarms);
  const dispatch = useAppDispatch();
  const insets = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const mainColor = useAppSelector(selectMainColor);
  const fontFamily = useAppSelector(selectFontFamily);
  const divRadius = useAppSelector(selectDivRadius);

  const updateEnableRefreshTab = useCallback(() => {
    const tmpArray: Array<boolean> = [];
    alarms.forEach(item => {
      tmpArray[item.key] = item.enable;
    });
    setEnableRefreshTab(tmpArray);
  }, [alarms]);

  useEffect(() => {
    // an 8am alarm is added by default
    if (alarms.length < 1) {
      const eightAMDate = new Date();
      eightAMDate.setHours(8, 0, 0, 0);

      const newAlarm: AlarmData = {
        key: 0,
        enable: true,
        name: '',
        time: eightAMDate,
        days: [true, true, true, true, true, false, false],
      };
      dispatch(addAlarm(newAlarm));
    }
    updateEnableRefreshTab();
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, [alarms, dispatch, updateEnableRefreshTab]);

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const menuIcon = useMemo(() => {
    return (
      <CustomIcon
        name="icon_menu"
        color={tabOptions.headerTextColor}
        size={mvs(24)}
        onPress={toggleLeftDrawer}
      />
    );
  }, [tabOptions, toggleLeftDrawer]);

  const tabName = useMemo(() => {
    return (
      <TouchableOpacity
        style={[
          styles.tabTitleContainer,
          { fontFamily: getFontFamily(fontFamily, 'SemiBold') },
        ]}
      >
        <Text
          numberOfLines={1}
          style={[
            styles.tabTitle,
            {
              color: tabOptions.headerTextColor,
              fontFamily: getFontFamily(fontFamily, 'Bold'),
            },
          ]}
        >
          {tabOptions.titre}
        </Text>
      </TouchableOpacity>
    );
  }, [tabOptions, fontFamily]);

  const alarmSelector = useCallback(
    (alarm: AlarmData) => {
      let alarmName = alarm.name;

      if (!alarmName) {
        alarmName = alarm.days
          .map((d, i) => (d ? i18n.t(dayz[i]).substring(0, 2) : ''))
          .filter(v => !!v)
          .join(' - ');
      }

      return (
        <View style={styles.alarmSelector} key={alarm.key}>
          <TouchableOpacity style={styles.alarmInfos} onPress={() => {}}>
            <CustomText style={styles.alarmHour} weight="SemiBold">
              {format(new Date(alarm.time), 'HH:mm')}
            </CustomText>
            <CustomText style={styles.alarmName} numberOfLines={1} weight="Regular">
              {alarmName}
            </CustomText>
          </TouchableOpacity>
          <Switch
            color={alarm.enable ? mainColor : '#FFFFFF'}
            trackColor={{ false: '#D9D9D9', true: '#D9D9D9' }}
            value={enableRefreshTab[alarm.key]}
            onValueChange={() => {}}
            style={styles.alarmSwitch}
          />
        </View>
      );
    },
    [enableRefreshTab, mainColor],
  );

  const alarmsDisplay = useMemo(() => {
    if (alarms.length === 0) {
      return;
    }
    return (
      <View style={[styles.alarmsDisplayContainer, { borderRadius: mvs(divRadius) }]}>
        <View style={styles.alarmIconCircle}>
          <CustomIcon name={'icon_alarm'} color={mainColor} size={mvs(35)} />
        </View>
        {alarms.map(alarm => alarmSelector(alarm))}
      </View>
    );
  }, [alarms, mainColor, alarmSelector, divRadius]);

  if (!isFocused) {
    return null;
  }

  return (
    <ScreenLayout
      headerContents={
        <>
          {menuIcon}
          {tabName}
        </>
      }
    >
      <SafeAreaView edges={['bottom']} style={[styles.container]}>
        <ScrollView
          contentContainerStyle={styles.scrollView}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          style={{ paddingBottom: 70 + insets.bottom }}
        >
          <CustomText style={styles.currentDay} weight="Regular">
            {formatForAlarm(currentDate)}
          </CustomText>
          <CustomText style={styles.currentTime} weight="Bold">
            {format(currentDate, 'HH:mm')}
          </CustomText>
          {alarmsDisplay}
        </ScrollView>

        <FloatingActionButton
          icon={'icon_plus_with_offset'}
          iconSize={isTablet ? mvs(20) : mvs(25)}
          containerStyle={{ right: undefined }}
          buttonStyle={styles.addButton}
          onPress={() => {}}
          disabled
          text={i18n.t('alarm.addAlarmButton')}
        />
      </SafeAreaView>
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  tabTitleContainer: {
    flex: 1,
    alignItems: isTablet ? 'center' : 'flex-start',
  },

  tabTitle: {
    flexShrink: 1,
    marginHorizontal: 12,
    color: COLORS.WHITE,
    fontSize: mvs(25),
    lineHeight: mvs(30),
  },

  container: {
    flex: 1,
    alignItems: 'center',
  },

  scrollView: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  currentDay: {
    color: COLORS.WHITE_80,
    fontSize: mvs(20),
  },

  currentTime: {
    color: COLORS.WHITE,
    fontSize: mvs(105),
    marginBottom: mvs(34),
  },
  addButton: {
    paddingLeft: isTablet ? 0 : 2,
    elevation: 40,
    pointerEvents: 'none',
  },
  alarmsDisplayContainer: {
    width: isTablet ? mvs(250) : WINDOW_WIDTH - mvs(60),
    backgroundColor: COLORS.WHITE,
    paddingTop: mvs(30),
    paddingBottom: mvs(20),
    paddingHorizontal: mvs(25),
  },
  alarmSelector: {
    flexDirection: 'row',
    marginVertical: mvs(10),
  },
  alarmInfos: {
    pointerEvents: 'none',
    flexDirection: 'column',
  },
  alarmHour: {
    color: '#474747',
    fontSize: mvs(40),
  },
  alarmName: {
    color: '#9C9C9C',
    fontSize: mvs(14),
    opacity: 0.7,
    marginRight: mvs(50),
  },
  alarmSwitch: {
    pointerEvents: 'none',
    alignSelf: 'center',
    paddingLeft: mvs(25),
    marginLeft: 'auto',
  },
  alarmIconCircle: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 100,
    width: mvs(71),
    height: mvs(71),
    shadowOffset: {
      width: mvs(-4),
      height: mvs(4),
    },
    shadowOpacity: 0.2,
    shadowColor: COLORS.BLACK,
    shadowRadius: 30,
    position: 'absolute',
    alignSelf: 'center',
    top: mvs(-35),
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
    pointerEvents: 'none',
  },
});
