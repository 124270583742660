import { ViewProps } from 'react-native';

import { CustomIcon } from './CustomIcon';
import { RecordButtonIconContainer } from './RecordButtonIconContainer';
import { COLORS } from '../../constants';

interface Props extends ViewProps {
  isShown: boolean;
  isRecording: boolean;
  iconSize: number | undefined;
}

export const RecordButtonIcon = ({ isShown, style, isRecording, iconSize }: Props) => {
  if (!isShown) {
    return null;
  }

  return (
    <RecordButtonIconContainer style={style}>
      <CustomIcon
        name={isRecording ? 'icon_stop' : 'icon_record'}
        color={COLORS.WHITE}
        size={iconSize || 40}
      />
    </RecordButtonIconContainer>
  );
};
