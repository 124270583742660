import { DrawerActions } from '@react-navigation/native';
import { Avatar } from '@rneui/themed';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  RefreshControl,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { mvs } from 'react-native-size-matters';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { COLORS, DataState, FONTS } from '../constants';
import { selectAppConfig, selectDivRadius } from '../features/appconfig/store/selectors';
import { selectBearerToken } from '../features/bearertoken/store/selectors';
import { showAlert } from '../features/modal/store/actions';
import { fetchTextDedications } from '../features/textDedication/store/actions';
import {
  selectTextDedications,
  selectTextDedicationsState,
} from '../features/textDedication/store/selectors';
import { dateFromSQLUTC, formatFromNow } from '../localization/datetime';
import i18n from '../localization/i18n';
import { DedicationModel, DedicationTabModel } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';
import { CustomIcon } from '../sdk/components/CustomIcon';
import { FloatingActionButton } from '../sdk/components/FloatingActionButton';
import { ScreenLayout } from '../sdk/components/ScreenLayout';
import { isTablet, sampleColorFromString } from '../sdk/constants';
import CustomText from '../components/CustomText';

export default function TextDedicationScreen({
  route,
  navigation,
}: LeftDrawerNavigatorScreenProps<'TextDedication'>) {
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector(selectAppConfig);
  const dedications = useAppSelector(selectTextDedications);
  const dedicationsState = useAppSelector(selectTextDedicationsState);
  const dedicationsListRef = useRef<FlatList<DedicationModel>>(null);
  const [refreshing, setRefreshing] = useState(false);
  const tabOptions = route.params.options as DedicationTabModel;
  const bearerToken = useAppSelector(selectBearerToken);
  const divRadius = useAppSelector(selectDivRadius);

  const fetchDedications = useCallback(() => {
    dispatch(fetchTextDedications(appConfig, bearerToken));
  }, [appConfig, bearerToken, dispatch]);

  useEffect(() => {
    fetchDedications();
  }, [fetchDedications]);

  useEffect(() => {
    if (dedicationsState !== DataState.LOADING) {
      setRefreshing(false);
    }

    if (dedicationsState === DataState.ERROR) {
      dispatch(
        showAlert({
          key: 'fetch-dedications-error',
          type: 'warning',
          text: i18n.t('alert.noConnection'),
          closable: false,
          autoCloseAfterMs: 2000,
        }),
      );
    }
  }, [dedicationsState, dispatch]);

  const scrollToTop = useCallback(
    () => dedicationsListRef.current?.scrollToOffset({ offset: 0 }),
    [],
  );

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchDedications();
  }, [fetchDedications]);

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const menuIcon = useMemo(() => {
    return (
      <CustomIcon
        name="icon_menu"
        color={tabOptions.headerTextColor}
        size={mvs(24)}
        onPress={toggleLeftDrawer}
      />
    );
  }, [toggleLeftDrawer, tabOptions]);

  const tabName = useMemo(() => {
    return (
      <View style={styles.titleContainer}>
        <TouchableOpacity onPress={scrollToTop}>
          <CustomText
            numberOfLines={1}
            style={[styles.title, { color: tabOptions.headerTextColor }]}
            weight="Bold"
          >
            {tabOptions.titre}
          </CustomText>
        </TouchableOpacity>
      </View>
    );
  }, [scrollToTop, tabOptions]);

  const renderDedication = useCallback(
    ({ item: dedication }: ListRenderItemInfo<DedicationModel>) => {
      const pseudo = dedication.pseudo?.trim() ?? '';
      const message = dedication.message?.trim() ?? '';
      const formattedDate = dedication.date_dedicace
        ? formatFromNow(dateFromSQLUTC(dedication.date_dedicace))
        : '';
      const avatarTitle = pseudo[0];
      const avatarColorStyle = { backgroundColor: sampleColorFromString(pseudo) };

      return (
        <View style={[styles.dedication, { borderRadius: mvs(divRadius, 0.35) }]}>
          <View style={styles.dedicationHeader}>
            <CustomText numberOfLines={1} style={styles.dedicationUser} weight="SemiBold">
              {pseudo}
            </CustomText>
            <CustomText numberOfLines={1} style={styles.dedicationDate} weight="Regular">
              - {formattedDate}
            </CustomText>
          </View>
          <CustomText style={styles.dedicationMessage} weight="Regular">
            {message}
          </CustomText>
          <Avatar
            title={avatarTitle}
            rounded
            size={mvs(51)}
            containerStyle={[styles.avatarContainer, avatarColorStyle]}
            titleStyle={styles.avatarText}
          />
        </View>
      );
    },
    [divRadius],
  );

  const dedicationsListKeyExtractor = useCallback(
    (item: DedicationModel) => `${item.id_dedicace}`,
    [],
  );

  return (
    <ScreenLayout
      headerContents={
        <>
          {menuIcon}
          {tabName}
        </>
      }
    >
      <View style={[styles.container]}>
        <FlatList
          ref={dedicationsListRef}
          data={dedications}
          renderItem={renderDedication}
          keyExtractor={dedicationsListKeyExtractor}
          contentContainerStyle={[styles.body]}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        />
        <FloatingActionButton
          icon={'icon_edit_with_offset'}
          onPress={() => {}}
          iconSize={mvs(17)}
          hidden={!appConfig.urlSiteRadio}
          disabled
          text={i18n.t('textDedication.writeDedication')}
          containerStyle={isTablet && { right: undefined }}
        />
      </View>
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: isTablet ? 'center' : 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    flexShrink: 1,
    marginHorizontal: 12,
    color: COLORS.WHITE,
    fontSize: mvs(25),
    lineHeight: mvs(30),
  },
  container: {
    flex: 1,
    alignItems: isTablet ? 'center' : 'flex-end',
  },
  body: {
    paddingHorizontal: mvs(32),
    paddingTop: mvs(18),
    paddingBottom: isTablet ? mvs(69) : 0,
    width: isTablet ? mvs(450, 0.35) : 320,
  },
  dedication: {
    backgroundColor: '#fff',
    paddingVertical: mvs(15, 0.35),
    paddingLeft: mvs(55, 0.35),
    paddingRight: mvs(35, 0.35),
    marginBottom: mvs(26, 0.35),
  },
  dedicationHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dedicationUser: {
    fontSize: mvs(16, 0.35),
    color: '#9C9C9C',
  },
  dedicationDate: {
    flex: 1,
    fontSize: mvs(14, 0.35),
    color: '#9C9C9C',
    marginLeft: mvs(3, 0.35),
  },
  dedicationMessage: {
    fontSize: mvs(20, 0.35),
    color: '#474747',
  },
  avatarContainer: {
    backgroundColor: '#BDBDBD',
    borderWidth: mvs(3, 0.35),
    borderColor: '#fff',
    position: 'absolute',
    left: mvs(-10, 0.35),
    top: mvs(-12, 0.35),
    shadowColor: '#000',
    shadowOffset: {
      width: mvs(4),
      height: mvs(4),
    },
    shadowOpacity: 0.2,
    shadowRadius: 30,
    elevation: 30,
  },
  avatarText: {
    color: '#fff',
  },
});
