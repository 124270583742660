import { DrawerActions } from '@react-navigation/native';
import { Image } from '@rneui/themed';
import { decode } from 'html-entities';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  Pressable,
  RefreshControl,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import base64 from 'react-native-base64';
import { mvs } from 'react-native-size-matters';
import GestureRecognizer from 'react-native-swipe-gestures';

import RssItemModalScreen from './RssItemModalScreen';
import { getRssContent } from '../api/mobileApi';
import { useAppSelector } from '../app/hooks';
import CustomText from '../components/CustomText';
import { COLORS } from '../constants';
import { selectAppConfig, selectDivRadius } from '../features/appconfig/store/selectors';
import { selectBearerToken } from '../features/bearertoken/store/selectors';
import { formatFromNow } from '../localization/datetime';
import { RssItemModel, RssTabModel } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';
import { CustomIcon } from '../sdk/components/CustomIcon';
import { ScreenLayout } from '../sdk/components/ScreenLayout';
import { IS_WEB, isTablet } from '../sdk/constants';

export default function RssScreen({
  route,
  navigation,
}): LeftDrawerNavigatorScreenProps<'Rss'> {
  const tabOptions = route.params.options as RssTabModel;
  const rssListRef = useRef<FlatList<RssItemModel>>(null);
  const appConfig = useAppSelector(selectAppConfig);
  const [rssItems, setRssItems] = useState([]);
  const [rssItemVisible, setRssItemVisible] = useState(false);
  const [currentRssItem, setCurrentRssItem] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [refreshing, setRefreshing] = useState(false);
  const bearerToken = useAppSelector(selectBearerToken);
  const divRadius = useAppSelector(selectDivRadius);

  const formatHTMLContent = useCallback(
    (content: string) =>
      content &&
      decode(
        content
          // TO DO: combine regex
          // removes HTML tags
          .replace(/<[^>]+>/g, '')
          // removes line breaks
          .replace(/(\r\n|\n|\r)/gm, ''),
      ),
    [],
  );

  const rssListKeyExtractor = useCallback((item: RssItemModel) => `${item.date}`, []);

  const fetchRssItems = useCallback(
    page => {
      if (IS_WEB && !tabOptions.urlFlux) {
        setRefreshing(false);
        return;
      }
      getRssContent(
        base64.encode(tabOptions.urlFlux),
        page,
        bearerToken,
        appConfig.idapplications,
      ).then(response => {
        setRefreshing(false);
        setRssItems(response.data);
        setCurrentPage(response.current_page);
        setLastPage(response.last_page);
      });
    },
    [appConfig, bearerToken, tabOptions],
  );

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const menuIcon = useMemo(() => {
    return (
      <CustomIcon
        name="icon_menu"
        color={tabOptions.headerTextColor}
        size={mvs(24)}
        onPress={toggleLeftDrawer}
      />
    );
  }, [tabOptions, toggleLeftDrawer]);

  const scrollToTop = useCallback(
    () => rssListRef.current?.scrollToOffset({ offset: 0 }),
    [],
  );

  const loadNextPage = useCallback(() => {
    if (currentPage !== lastPage) {
      getRssContent(
        base64.encode(tabOptions.urlFlux),
        currentPage + 1,
        bearerToken,
        appConfig.idapplications,
      ).then(response => {
        setCurrentPage(currentPage + 1);
        setRssItems(rssItems.concat(response.data));
      });
    }
  }, [appConfig, bearerToken, currentPage, lastPage, rssItems, tabOptions]);

  const tabName = useMemo(() => {
    return (
      <TouchableOpacity style={styles.tabTitleContainer} onPress={scrollToTop}>
        <CustomText
          numberOfLines={1}
          style={[styles.tabTitle, { color: tabOptions.headerTextColor }]}
          weight="Bold"
        >
          {tabOptions.titre}
        </CustomText>
      </TouchableOpacity>
    );
  }, [scrollToTop, tabOptions]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchRssItems(1);
  }, [fetchRssItems]);

  useEffect(() => {
    fetchRssItems(1);
  }, [fetchRssItems]);

  // loads 2 pages instead of 1 by default on tablet
  useEffect(() => {
    if (rssItems.length !== 0 && currentPage === 1 && isTablet) {
      loadNextPage();
    }
  }, [rssItems, currentPage, loadNextPage]);

  const firstRssItem = useMemo(() => {
    if (rssItems && rssItems[0] !== undefined) {
      const item: RssItemModel = rssItems[0];
      return (
        <Pressable
          onPress={() => {
            setRssItemVisible(true);
            setCurrentRssItem(item);
          }}
        >
          <View style={[styles.firstItem, { borderRadius: mvs(divRadius) }]}>
            <View style={styles.itemTextContainer}>
              <CustomText style={styles.itemDate} numberOfLines={1} weight="Regular">
                {formatFromNow(new Date(item.date), { addSuffix: true })}
              </CustomText>
              <CustomText numberOfLines={3} style={styles.itemTitle} weight="Regular">
                {decode(item.title)}
              </CustomText>
              {isTablet && (
                <CustomText weight="Regular" numberOfLines={2} style={styles.itemContent}>
                  {formatHTMLContent(item.content)}
                </CustomText>
              )}
            </View>
            <View style={styles.itemImageContainer}>
              <Image
                source={{ uri: item.image }}
                resizeMode="cover"
                style={[styles.itemPicture, { borderRadius: mvs(divRadius) }]}
              />
            </View>
          </View>
        </Pressable>
      );
    } else {
      return <></>;
    }
  }, [rssItems, formatHTMLContent]);

  const renderOtherRssItem = useCallback(
    ({ item: item }: ListRenderItemInfo<RssItemModel>) => {
      if (rssItems !== undefined) {
        return (
          <Pressable
            onPress={() => {
              setRssItemVisible(true);
              setCurrentRssItem(item);
            }}
            style={styles.otherItemContainer}
          >
            <View style={[styles.otherItem, { borderRadius: mvs(divRadius) }]}>
              <CustomText style={styles.itemDate} numberOfLines={1} weight="Regular">
                {formatFromNow(new Date(item.date), { addSuffix: true })}
              </CustomText>
              <CustomText
                numberOfLines={3}
                style={styles.otherItemTitle}
                weight="Regular"
              >
                {decode(item.title)}
              </CustomText>
            </View>
            <View style={styles.otherItemPictureParent}>
              <Image
                source={{ uri: item.image }}
                resizeMode="cover"
                style={[
                  styles.itemPicture,
                  styles.otherItemPicture,
                  { borderRadius: mvs(divRadius) },
                ]}
              />
            </View>
          </Pressable>
        );
      } else {
        return <></>;
      }
    },
    [rssItems, divRadius],
  );

  return (
    <ScreenLayout
      headerContents={
        <>
          {menuIcon}
          {tabName}
        </>
      }
    >
      <View style={styles.container}>
        <FlatList
          ref={rssListRef}
          // removes first item of list
          data={rssItems !== undefined ? rssItems.slice(1) : []}
          renderItem={renderOtherRssItem}
          ListHeaderComponent={firstRssItem}
          ListHeaderComponentStyle={styles.firstItemContainer}
          keyExtractor={rssListKeyExtractor}
          showsVerticalScrollIndicator={false}
          columnWrapperStyle={isTablet && styles.columnWrapperStyle}
          numColumns={isTablet ? 2 : 1}
          onEndReached={loadNextPage}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        />
      </View>
      <GestureRecognizer
        onSwipeDown={() => setRssItemVisible(false)}
        config={{
          velocityThreshold: 3,
        }}
      >
        <RssItemModalScreen
          item={currentRssItem}
          visible={rssItemVisible}
          onCancel={() => {
            setRssItemVisible(false);
          }}
        />
      </GestureRecognizer>
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  tabTitleContainer: {
    flex: 1,
    alignItems: isTablet ? 'center' : 'flex-start',
  },
  tabTitle: {
    flexShrink: 1,
    marginHorizontal: 12,
    color: COLORS.WHITE,
    fontSize: mvs(25),
    lineHeight: mvs(30),
  },
  container: {
    flex: 1,
  },
  firstItemContainer: {
    width: isTablet ? '100%' : 'auto',
  },
  firstItem: {
    flexDirection: isTablet ? 'row-reverse' : 'column',
    backgroundColor: COLORS.WHITE,
    marginHorizontal: isTablet ? mvs(32) : 34,
    paddingTop: isTablet ? mvs(20) : 20,
    paddingBottom: isTablet ? mvs(20) : mvs(32),
    paddingHorizontal: isTablet ? mvs(20) : mvs(31),
    marginBottom: isTablet ? mvs(30) : mvs(12),
  },

  itemTextContainer: {
    flex: IS_WEB ? undefined : 1,
    marginLeft: isTablet ? mvs(8) : 0,
    paddingRight: isTablet ? mvs(25) : 0,
  },

  itemImageContainer: {
    flex: 1,
    marginRight: isTablet ? mvs(15) : 0,
  },

  itemTitle: {
    color: isTablet ? '#474747' : COLORS.BLACK_80,
    fontSize: isTablet ? mvs(16, 0.4) : 20,
    lineHeight: isTablet ? mvs(18) : 24,
    marginTop: isTablet ? mvs(1) : 3,
  },
  itemDate: {
    color: COLORS.BLACK_50,
    fontSize: isTablet ? mvs(9) : mvs(12, 0.4),
    lineHeight: isTablet ? mvs(16.37, 0.4) : 16.37,
    height: mvs(16, 0.4),
    marginTop: isTablet ? mvs(4) : 0,
  },
  itemPicture: {
    flexShrink: 1,
    aspectRatio: 9 / 5,
    marginTop: isTablet ? 0 : 7,
  },
  itemContent: {
    fontSize: mvs(11, 0.4),
    marginTop: mvs(3),
    lineHeight: mvs(16),
    color: '#474747',
  },
  columnWrapperStyle: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: isTablet ? mvs(32) : mvs(34),
  },
  otherItemContainer: {
    width: isTablet ? '48.5%' : '100%',
  },
  otherItem: {
    backgroundColor: COLORS.WHITE,
    marginRight: isTablet ? 0 : 34,
    marginLeft: isTablet ? mvs(15) : 54,
    paddingTop: isTablet ? mvs(8) : 24,
    paddingBottom: isTablet ? mvs(8) : 26,
    paddingRight: isTablet ? mvs(50) : 31,
    paddingLeft: isTablet ? mvs(60) : 78,
    marginBottom: isTablet ? mvs(12) : 12,
  },

  otherItemTitle: {
    fontSize: isTablet ? mvs(10) : 16,
    height: isTablet ? mvs(50) : IS_WEB ? 60 : 70,
    color: isTablet ? '#474747' : undefined,
    lineHeight: isTablet ? mvs(13) : undefined,
  },

  otherItemPicture: {
    aspectRatio: 1,
    width: isTablet ? mvs(65) : 80,
    height: isTablet ? mvs(65) : 80,
    borderColor: '#F3F3F3',
    borderWidth: isTablet ? mvs(4) : 4,
    marginLeft: isTablet ? 0 : 34,
    marginTop: isTablet ? mvs(8) : 28,
  },

  otherItemPictureParent: {
    position: 'absolute',
    shadowColor: COLORS.BLACK,
    shadowOffset: IS_WEB ? undefined : { width: mvs(4), height: mvs(4) },
    shadowRadius: IS_WEB ? 0 : mvs(4),
    shadowOpacity: 0.25,
  },
});
