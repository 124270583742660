import { ReactNode } from 'react';
import { StyleSheet, ViewProps } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';
import styled from 'styled-components/native';

import { useAppSelector } from '../../app/hooks';
import { COLORS } from '../../constants';
import { selectMainColor } from '../../features/appconfig/store/selectors';

interface Props extends ViewProps {
  children: ReactNode;
}

export const RecordButtonIconContainer = ({ style, children }: Props) => {
  const mainColor = useAppSelector(selectMainColor);

  return (
    <Button style={[style, { backgroundColor: mainColor }]}>
      <LinearGradient
        // gradient initialized with a single color
        // prepared for future addition of a secondary color.
        colors={[mainColor, mainColor]}
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
        style={styles.background}
      >
        {children}
      </LinearGradient>
    </Button>
  );
};

const Button = styled.View`
  width: ${mvs(58)};
  aspect-ratio: 1;
  border-radius: ${mvs(44)}px;
  border-color: ${COLORS.WHITE};
  border-width: 5px;
  align-self: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: mvs(58),
    alignItems: 'center',
    justifyContent: 'center',
  },
});
