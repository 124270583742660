import { ActivityIndicator, ViewProps } from 'react-native';
import styled from 'styled-components/native';

import { PlayPauseIcon } from './PlayPauseIcon';
import { PlayPauseIconContainer } from './PlayPauseIconContainer';
import { COLORS } from '../../constants';
import { useIsLoading } from '../../hooks/useIsLoading';

interface RadioTitlePlayPauseIconProps extends ViewProps {
  isShown: boolean;
  isPlaying: boolean;
  iconSize: number | undefined;
}
export const RadioTitlePlayPauseIcon = ({
  isShown,
  style,
  isPlaying,
  iconSize,
}: RadioTitlePlayPauseIconProps) => {
  const isLoading = useIsLoading();

  if (!isShown) {
    return null;
  }

  return isLoading ? (
    <PlayPauseIconContainer style={style}>
      <Loader color={COLORS.WHITE} size={'large'} />
    </PlayPauseIconContainer>
  ) : (
    <PlayPauseIcon isPlaying={isPlaying} style={style} iconSize={iconSize} />
  );
};

const Loader = styled(ActivityIndicator)`
  margin-left: 0px;
`;
