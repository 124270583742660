import { DrawerActions, useFocusEffect } from '@react-navigation/native';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { mvs } from 'react-native-size-matters';
import ReactPlayer from 'react-player';

import ContactModal from './ContactModalScreen';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { COLORS, DEFAULT_COVER, FONTS } from '../constants';
import {
  selectAppConfig,
  selectCover,
  selectDisplayBuyButton,
  selectDisplayContactButton,
  selectDisplayShareButton,
  selectFontFamily,
  selectRadioTab,
  selectSleepTimer,
} from '../features/appconfig/store/selectors';
import { updateCurrentRadio, updateIsHD } from '../features/currentradio/store/actions';
import { selectCurrentRadio, selectIsHD } from '../features/currentradio/store/selectors';
import { selectCurrentTrack } from '../features/currenttrack/store/selectors';
import { showAlert } from '../features/modal/store/actions';
import { useCurrentTrackUpdater } from '../hooks/useCurrentTrackUpdater';
import i18n from '../localization/i18n';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';
import { getUrlFromImageName } from '../sdk/api/images';
import { CoverImage } from '../sdk/components/CoverImage';
import { CustomIcon } from '../sdk/components/CustomIcon';
import { GradientBackground } from '../sdk/components/GradientBackground';
import { LogoImage } from '../sdk/components/LogoImage';
import { PlayPauseButton } from '../sdk/components/PlayPauseButton';
import { ScreenLayout } from '../sdk/components/ScreenLayout';
import { ScrollableText } from '../sdk/components/ScrollableText';
import { SleepTimer } from '../sdk/components/SleepTimer';
import { IS_IOS, isTablet } from '../sdk/constants';
import useOrientation from '../sdk/hooks/useOrientation';
import { getFontFamily } from '../sdk/helpers/fontMapper';
import CustomText from '../components/CustomText';

export const PlayerScreen = ({
  navigation,
}: LeftDrawerNavigatorScreenProps<'Player'>) => {
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector(selectAppConfig);
  const radioTab = useAppSelector(selectRadioTab);
  const currentTrack = useAppSelector(selectCurrentTrack);
  const displayContactButton = useAppSelector(selectDisplayContactButton);
  const displayShareButton = useAppSelector(selectDisplayShareButton);
  const displayBuyButton = useAppSelector(selectDisplayBuyButton);
  const defaultCover = useAppSelector(selectCover);
  const showSleepTimer = useAppSelector(selectSleepTimer);
  const fontFamily = useAppSelector(selectFontFamily);

  const orientation = useOrientation();
  const currentRadio = useAppSelector(selectCurrentRadio);
  const isHD = useAppSelector(selectIsHD);
  const [showContactModal, setShowContactModal] = useState(false);

  const [voted, setVoted] = useState(false);
  const [voteChoice, setVoteChoice] = useState(0);
  const isTabletPortrait = orientation === 'PORTRAIT' && isTablet;
  const isTabletLandscape = orientation === 'LANDSCAPE' && isTablet;

  const [isAppStarted, setIsAppStarted] = useState(false);
  const [isRadioStarted, setIsRadioStarted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);

  useCurrentTrackUpdater();

  useFocusEffect(
    useCallback(() => {
      const startInterval = setInterval(() => {
        if (currentRadio.id === -1) {
          dispatch(updateCurrentRadio(radioTab.flux[0]));
        } else {
          const isRadioInConfig = radioTab?.flux.findIndex(function (e) {
            return e.id === currentRadio.id;
          });
          if (isRadioInConfig === -1) {
            dispatch(updateCurrentRadio(radioTab?.flux[0]));
          }
        }
        setIsAppStarted(true);
        clearInterval(startInterval);
      }, 2000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [radioTab]),
  );

  useEffect(() => {
    if (currentRadio.id !== -1) {
      const radio = radioTab?.flux.find(function (e) {
        return e.id === currentRadio.id;
      });
      if (radio) {
        dispatch(updateCurrentRadio(radio));
      }
    }
    if (currentRadio.id === -1) {
      dispatch(updateCurrentRadio(radioTab.flux[0]));
    } else {
      const isRadioInConfig = radioTab?.flux.findIndex(function (e) {
        return e.id === currentRadio.id;
      });
      if (isRadioInConfig === -1) {
        dispatch(updateCurrentRadio(radioTab?.flux[0]));
      }
    }
  }, [currentRadio, isAppStarted, isHD, radioTab, dispatch, isRadioStarted]); // Ne pas ajouter PlayStream (crée un bug fait relance la radio pour les radios RK SD)

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const toggleRightDrawer = useCallback(() => {
    navigation.getParent()?.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const tabletCoverImageStyle = {
    width: isTabletPortrait ? mvs(380) : mvs(200),
    alignSelf: 'center',
  };
  const menuIcon = useMemo(() => {
    return (
      <View style={styles.menuIconContainer}>
        <CustomIcon
          name="icon_menu"
          color={COLORS.WHITE}
          size={mvs(24)}
          onPress={toggleLeftDrawer}
          testID="menu_icon"
          accessibilityLabel="menu_icon"
        />
      </View>
    );
  }, [toggleLeftDrawer]);

  const webAudioPlayer = useMemo(() => {
    if (!currentRadio) {
      return;
    }
    return (
      <ReactPlayer
        url={currentRadio.urlFlux}
        playing={isStarted}
        width="0"
        height="0"
        style={{ display: 'none' }}
        audioonly="true"
      />
    );
  }, [currentRadio, isStarted]);

  const radioName = useMemo(() => {
    if (currentRadio.logoappfield) {
      return (
        <View style={styles.headerLogoContainer}>
          <LogoImage
            source={{
              uri: getUrlFromImageName(appConfig, currentRadio.logoappfield),
            }}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.titleContainer}>
          <CustomText
            numberOfLines={1}
            style={[styles.title, { color: radioTab.nomColor }]}
            weight="Bold"
          >
            {currentRadio.nomFlux}
          </CustomText>
        </View>
      );
    }
  }, [appConfig, currentRadio, radioTab]);

  const switchRadioIcon = useMemo(() => {
    if (radioTab.flux.length <= 1) {
      return null;
    }
    return (
      <CustomIcon
        name="icon_switch_radio"
        color={COLORS.WHITE_80}
        size={isTablet ? mvs(28) : 32}
        onPress={toggleRightDrawer}
        testID="multistream_icon"
        accessibilityLabel="multistream_icon"
      />
    );
  }, [radioTab, toggleRightDrawer]);

  const contactIcon = useMemo(() => {
    if (!displayContactButton) {
      return null;
    }
    return (
      <CustomIcon
        name="icon_contact"
        color={COLORS.WHITE}
        size={24}
        onPress={() =>
          dispatch(
            showAlert({
              key: 'only-mobile',
              type: 'info',
              text: i18n.t('alert.onlyMobile'),
              closable: false,
              autoCloseAfterMs: 2000,
            }),
          )
        }
        style={styles.bottomIcon}
      />
    );
  }, [displayContactButton, dispatch]);

  const buyIcon = useMemo(() => {
    if (!displayBuyButton || !currentTrack.url) {
      return null;
    }
    return (
      <CustomIcon
        name="icon_shop"
        color={COLORS.WHITE}
        size={24}
        onPress={() =>
          dispatch(
            showAlert({
              key: 'only-mobile',
              type: 'info',
              text: i18n.t('alert.onlyMobile'),
              closable: false,
              autoCloseAfterMs: 2000,
            }),
          )
        }
        style={styles.bottomIcon}
      />
    );
  }, [currentTrack, displayBuyButton, dispatch]);

  const shareIcon = useMemo(() => {
    if (!displayShareButton) {
      return null;
    }
    return (
      <CustomIcon
        name="icon_share_player"
        color={COLORS.WHITE}
        size={24}
        onPress={() =>
          dispatch(
            showAlert({
              key: 'only-mobile',
              type: 'info',
              text: i18n.t('alert.onlyMobile'),
              closable: false,
              autoCloseAfterMs: 2000,
            }),
          )
        }
        style={styles.bottomIcon}
      />
    );
  }, [displayShareButton, dispatch]);

  const hdIcon = useMemo(() => {
    if (!currentRadio.urlFluxHD && !currentRadio.urlFluxBD) {
      return null;
    }
    return (
      <CustomIcon
        name={isHD ? 'icon_hd' : 'icon_sd'}
        color={COLORS.WHITE}
        onPress={() => {
          dispatch(updateIsHD(!isHD));
          dispatch(updateCurrentRadio(currentRadio));
        }}
        style={styles.bottomIcon}
      />
    );
  }, [currentRadio, dispatch, isHD]);

  const voteBlock = useMemo(() => {
    if (
      radioTab.voteOption !== 'YES' ||
      !currentRadio?.urlFlux ||
      currentRadio?.urlFlux.indexOf('radioking.com') === -1
    ) {
      return null;
    }
    return (
      <View style={styles.voteBlock}>
        <CustomIcon
          name="icon_vote_like"
          color={radioTab.titleLabelColor}
          size={mvs(32)}
          style={[
            styles.voteIcon,
            voted && voteChoice === -1 ? styles.voteIconNotChosen : null,
          ]}
          disabled
          onPress={() =>
            dispatch(
              showAlert({
                key: 'only-mobile',
                type: 'info',
                text: i18n.t('alert.onlyMobile'),
                closable: false,
                autoCloseAfterMs: 2000,
              }),
            )
          }
        />
        <CustomIcon
          name="icon_vote_dislike"
          color={radioTab.titleLabelColor}
          size={mvs(32)}
          style={[
            styles.voteIcon,
            voted && voteChoice === 1 ? styles.voteIconNotChosen : null,
          ]}
          disabled
          onPress={() =>
            dispatch(
              showAlert({
                key: 'only-mobile',
                type: 'info',
                text: i18n.t('alert.onlyMobile'),
                closable: false,
                autoCloseAfterMs: 2000,
              }),
            )
          }
        />
      </View>
    );
  }, [currentRadio, dispatch, radioTab, voteChoice, voted]);

  return (
    <ScreenLayout
      contentStyle={styles.content}
      headerStyle={[styles.header, isTabletPortrait && { marginBottom: mvs(8) }]}
      headerContents={
        <>
          {menuIcon}
          {!isTabletLandscape && radioName}
          <View style={styles.headerControls}>
            {isTablet && showSleepTimer && <SleepTimer />}
            <View style={styles.switchRadioIcon}>{switchRadioIcon}</View>
          </View>
        </>
      }
    >
      <View style={styles.body}>
        <CoverImage
          source={
            currentTrack.image
              ? { uri: currentTrack.image }
              : defaultCover
                ? { uri: defaultCover }
                : DEFAULT_COVER
          }
          sizeStyle={isTablet && tabletCoverImageStyle}
          coverType={radioTab?.coverType}
        />
        {webAudioPlayer}
        <PlayPauseButton
          iconSize={mvs(25)}
          style={styles.playPauseButton}
          isStarted={isStarted}
          onPress={() => setIsStarted(!isStarted)}
        />
      </View>
      <View
        style={[
          styles.trackInfo,
          {
            backgroundColor:
              radioTab?.fondTitrage === 'NO' ? undefined : radioTab?.bottomColor,
          },
          isTabletPortrait
            ? // eslint-disable-next-line react-native/no-inline-styles
              { marginBottom: mvs(30), paddingVertical: mvs(25), flex: 0 }
            : undefined,
        ]}
      >
        <ScrollableText
          style={[
            styles.trackName,
            {
              color: radioTab.titleLabelColor,
              fontFamily: getFontFamily(fontFamily, 'Bold'),
            },
          ]}
        >
          {currentTrack.title}
        </ScrollableText>
        <ScrollableText
          style={[
            styles.trackArtist,
            {
              color: radioTab?.artistLabelColor,
              fontFamily: getFontFamily(fontFamily, 'Regular'),
            },
          ]}
        >
          {currentTrack.artist}
        </ScrollableText>
        {voteBlock}
      </View>

      <GradientBackground
        style={IS_IOS ? styles.gradientBottomBlock : null}
        invert={true}
      >
        <View style={styles.bottomBlock}>
          {contactIcon}
          {shareIcon}
          {buyIcon}
          {hdIcon}
          {!isTablet && showSleepTimer ? (
            <View style={styles.sleepTimerMobileContainer}>
              <SleepTimer />
            </View>
          ) : null}
        </View>
        <SafeAreaView edges={['bottom']} />
      </GradientBackground>
      <ContactModal visible={showContactModal} onRequestClose={setShowContactModal} />
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  content: {
    justifyContent: 'space-between',
  },
  header: {
    justifyContent: 'space-between',
  },
  menuIconContainer: {
    flex: isTablet ? 1 : 0,
  },
  headerControls: {
    flex: isTablet ? 1 : 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  switchRadioIcon: {
    marginLeft: isTablet ? 25 : 10,
  },
  headerLogoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 5,
  },
  titleContainer: {
    marginLeft: 20,
    alignItems: isTablet ? 'center' : 'flex-start',
    justifyContent: 'center',
    alignSelf: 'center',
    overflow: 'hidden',
    flex: isTablet ? 0 : 1,
  },
  title: {
    marginHorizontal: 12,
    color: COLORS.WHITE,
    fontSize: mvs(25),
    lineHeight: mvs(34),
    width: 150,
  },
  body: {
    marginHorizontal: mvs(16),
  },
  playPauseButton: {
    position: 'absolute',
    bottom: mvs(-26.5),
    alignSelf: 'center',
  },
  trackInfo: {
    flex: 1,
    marginTop: mvs(40),
    marginBottom: isTablet ? mvs(15) : 0,
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: mvs(180),
  },
  trackName: {
    color: COLORS.WHITE,
    fontSize: mvs(31.67),
    lineHeight: mvs(43.19),
    paddingHorizontal: 20,
    maxWidth: isTablet ? undefined : 300,
  },
  trackArtist: {
    marginTop: mvs(4),
    color: COLORS.MANATEE,
    fontSize: mvs(25.33),
    lineHeight: mvs(34.55),
    paddingHorizontal: 20,
    maxWidth: isTablet ? undefined : 300,
  },
  voteBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: mvs(10),
  },
  voteIcon: {
    marginHorizontal: isTablet ? mvs(16) : 40,
  },
  voteIconNotChosen: {
    opacity: 0.5,
  },
  gradientBottomBlock: {
    zIndex: 2,
  },
  bottomBlock: {
    paddingTop: isTablet ? 0 : 15,
    paddingBottom: mvs(10),
    paddingHorizontal: mvs(32),
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 45,
    marginBottom: 8,
  },
  bottomIcon: {
    marginHorizontal: mvs(8),
    pointerEvents: 'none',
  },
  sleepTimerMobileContainer: {
    position: 'absolute',
    right: mvs(32),
    bottom: mvs(1),
    pointerEvents: 'none',
  },
});
