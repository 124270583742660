import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';

import { RecordButton } from './RecordButton';
import { useAppSelector } from '../../app/hooks';
import CustomText from '../../components/CustomText';
import { selectMainColor } from '../../features/appconfig/store/selectors';
import { UseToggleRecording } from '../../hooks/useToggleRecording';
import i18n from '../../localization/i18n';

interface Props extends UseToggleRecording {
  isActive: boolean;
  sizeStyle?: StyleProp<ViewStyle>;
  width: number;
}

export const AudioDedication = (props: Props) => {
  const { isSent, toggleRecording } = props;
  const mainColor = useAppSelector(selectMainColor);

  return (
    <View style={styles.wrapper}>
      <View style={[styles.container, props.sizeStyle]}>
        <View style={styles.backgroundContainer}>
          <LinearGradient
            // gradient initialized with a single color
            // prepared for future addition of a secondary color.
            colors={isSent ? ['#2ED573', '#2ED573'] : [mainColor, mainColor]}
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 0.5 }}
            style={styles.background}
          >
            <CustomText style={styles.statusText} numberOfLines={1} weight="Bold">
              {i18n.t('audioDedication.record')}
            </CustomText>
          </LinearGradient>
        </View>
      </View>
      <View style={styles.bottomActions}>
        <RecordButton
          isRecording={false}
          isRecorded={false}
          toggleRecording={() => {
            toggleRecording();
          }}
          countdown={0}
          iconSize={mvs(27)}
          style={styles.bottomAction}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingBottom: mvs(26.5),
    marginTop: mvs(26.5),
  },
  container: {
    aspectRatio: 1,
    borderRadius: mvs(1000),
    borderWidth: mvs(7),
    borderColor: '#FFFFFF4D',
    overflow: 'hidden',
  },
  backgroundContainer: {
    margin: mvs(-7),
  },
  background: {
    aspectRatio: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusText: {
    color: '#fff',
    fontSize: mvs(40),
    paddingHorizontal: 20,
  },
  bottomActions: {
    position: 'absolute',
    bottom: 0,
    alignSelf: 'center',
    flexDirection: 'row',
    zIndex: 1,
  },
  bottomAction: {
    shadowColor: '#000',
    shadowOpacity: 0.4,
    shadowRadius: mvs(0),
    elevation: mvs(30),
    pointerEvents: 'none',
  },
});
